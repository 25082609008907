import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GolfCourseConfig } from 'common/infrastructure/modules/game/interfaces/GolfCourseTypes';
import { Tournament } from 'common/infrastructure/modules/game/interfaces/TournamentTypes';

import { ContestBalanceReposnse } from 'application/modules/contest/useCases/hooks/useCaseGetCustomerContestBalance';

export interface ContestState {
  customerContestBalance?: ContestBalanceReposnse | undefined;
  golfCourseConfig?: GolfCourseConfig[] | undefined;
  tournaments?: Tournament[] | undefined;
}

const contestSlice = createSlice({
  name: 'contest',
  initialState: {},
  reducers: {
    setCustomerContestBalance: (
      state: ContestState,
      action: PayloadAction<ContestBalanceReposnse | undefined>,
    ) => ({
      ...state,
      customerContestBalance: action.payload,
    }),
    setGolfCourseConfig: (
      state: ContestState,
      action: PayloadAction<GolfCourseConfig | undefined>,
    ) => ({
      ...state,
      golfCourseConfig: action.payload,
    }),
    setTournaments: (state: ContestState, action: PayloadAction<Tournament[] | undefined>) => ({
      ...state,
      tournaments: action.payload,
    }),
  },
});

export const { setCustomerContestBalance, setGolfCourseConfig, setTournaments } =
  contestSlice.actions;
export default contestSlice.reducer;
